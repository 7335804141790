import React from "react";

interface OwnProps {
  height?: number;
  width?: number;
  color?: string;
}
const BASE_WIDTH = 12;
const BASE_HEIGHT = 13;
const BASE_COLOR = "#FF5050"; // danger text
export default function ErrorIcon(props: OwnProps) {
  const width = props.width || BASE_WIDTH;
  const height = props.height || BASE_HEIGHT;

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 12.5C2.6862 12.5 0 9.8138 0 6.5C0 3.1862 2.6862 0.5 6 0.5C9.3138 0.5 12 3.1862 12 6.5C12 9.8138 9.3138 12.5 6 12.5ZM6 11.3C7.27304 11.3 8.49394 10.7943 9.39411 9.89411C10.2943 8.99394 10.8 7.77304 10.8 6.5C10.8 5.22696 10.2943 4.00606 9.39411 3.10589C8.49394 2.20571 7.27304 1.7 6 1.7C4.72696 1.7 3.50606 2.20571 2.60589 3.10589C1.70571 4.00606 1.2 5.22696 1.2 6.5C1.2 7.77304 1.70571 8.99394 2.60589 9.89411C3.50606 10.7943 4.72696 11.3 6 11.3Z"
        fill={props.color || BASE_COLOR}
      />
      <path
        d="M6.00948 9.5C6.48833 9.5 6.87651 9.10317 6.87651 8.61366C6.87651 8.12415 6.48833 7.72732 6.00948 7.72732C5.53063 7.72732 5.14245 8.12415 5.14245 8.61366C5.14245 9.10317 5.53063 9.5 6.00948 9.5Z"
        fill={props.color || BASE_COLOR}
      />
      <path
        d="M7.01001 4.5227C7.01001 3.95681 6.56249 3.5 6.00959 3.5C5.84584 3.50003 5.68461 3.54117 5.54006 3.61982C5.39551 3.69846 5.27207 3.8122 5.18059 3.95103C5.0891 4.08986 5.03238 4.24954 5.0154 4.41603C4.99842 4.58253 5.0217 4.75073 5.0832 4.90587C5.45269 5.84403 6.00959 7.2499 6.00959 7.2499L6.93731 4.90587C6.98266 4.78792 7.01001 4.65838 7.01001 4.5227Z"
        fill={props.color || BASE_COLOR}
      />
    </svg>
  );
}

import Button, { ButtonType, Props as ButtonProps } from "components/Button";
import NextLink from "next/link";
import React from "react";
import { TextType } from "./Text_DEPRECATED";

interface OwnProps {
  href: string;
  attrTitle?: string;
  textType?: TextType;
  styleType?: ButtonType;
  newTab?: boolean;
}

type Props = OwnProps & ButtonProps;

/**
 * Generic Chakra Link wrapper to allow easy styleguide matching / updates
 * @deprecated - use ButtonLink from beta instead instead

 * @param props
 */
export default function ButtonLink(props: Props): JSX.Element {
  const { href, attrTitle, styleType, textType, newTab, ...otherProps } = props;
  return (
    <NextLink href={href}>
      <a
        title={attrTitle}
        target={!newTab ? "_self" : "_blank"}
        rel="noopener noreferrer"
      >
        <Button
          styleType={styleType ?? ButtonType.TEXT_BUTTON}
          textType={textType ?? undefined}
          _hover={{ cursor: "pointer" }}
          variant="ghost"
          {...otherProps}
        >
          {otherProps.children}
        </Button>
      </a>
    </NextLink>
  );
}

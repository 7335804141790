import { UpdateAccountParams, UpdateAccountRes } from "core/account/types";
import { getAuthClient } from "lib/http/api";
import { encodeToSnakeCase } from "lib/http/formatForServer";

export async function patchCustomer(
  userRefId: string,
  params: UpdateAccountParams
): Promise<UpdateAccountRes> {
  const body = encodeToSnakeCase(params);
  return getAuthClient()
    .patch(`/v1/users/${userRefId}`, body)
    .then((res) => {
      if (res.status !== 200) {
        return {
          error: `${res.status}: ${res.statusText}`,
          success: false,
        };
      }
      return {
        error: null,
        success: true,
      };
    })
    .catch((e: any) => {
      return {
        error: e,
        succes: false,
      };
    });
}

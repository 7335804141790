import { JobApplyFunnelSteps } from "core/jobs/types";
import React, { createContext, useContext } from "react";

export interface JobValues {
  applyFunnelStep: JobApplyFunnelSteps | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  phoneNumber: string | null;
  phoneVerificationCode: string | null;
  selectedResumeRefId: string | null;
  referenceNumber: string | null;
}

export const initialValues: JobValues = {
  applyFunnelStep: null,
  email: null,
  firstName: null,
  lastName: null,
  phoneNumber: null,
  phoneVerificationCode: null,
  referenceNumber: null,
  selectedResumeRefId: null,
};

type JobValuesAction = {
  type: "job/SET";
  value: Partial<JobValues>;
};

function jobReducer(state: JobValues, action: JobValuesAction): JobValues {
  switch (action.type) {
    case "job/SET": {
      return {
        ...state,
        ...action.value,
      };
    }

    default:
      return state;
  }
}

export interface JobProviderValue {
  setJobValues: (value: Partial<JobValues>) => void;
  getJobValues: () => JobValues;
}

const JobContext = createContext<JobProviderValue | null>(null);

export function useJobValues() {
  const context = useContext(JobContext);
  if (!context) {
    throw new Error("useJobValues() must be used within a JobProvider.");
  }
  return context;
}

type Props = {
  [k in keyof JobValues]?: JobValues[k];
} & { children: React.ReactNode };

export function JobProvider(props: Props) {
  const { children, ...otherProps } = props;
  const [jobState, jobDispatch] = React.useReducer<
  React.Reducer<JobValues, JobValuesAction>
  >(jobReducer, {
    ...initialValues,
    ...otherProps,
  });
  const getJobValues = React.useCallback((): JobValues => {
    return jobState;
  }, [jobState]);
  const setJobValues = React.useCallback(
    (value: Partial<JobValues>): void => {
      jobDispatch({ type: "job/SET", value });
    },
    [jobDispatch]
  );

  return (
    <JobContext.Provider value={{ getJobValues, setJobValues }}>
      {children}
    </JobContext.Provider>
  );
}

import { Flex } from "@chakra-ui/react";
import Button, { ButtonType } from "components/Button";
import Heading, { HeadingType } from "components/Heading";
import Text, { TextType } from "components/Text_DEPRECATED";
import { ChangeEvent } from "react";
import Input from "components/Input";
import { useAppValues } from "lib/context/AppContext";
import Modal from "components/Modal";
import { ScreenSizes } from "core/home/types";

interface OwnProps {
  isOpen: boolean;
  onCancel: () => void;
  handleSubmit: () => void;
  handleResend: () => void;
  error: string | null;
  isLoading: boolean;
}

export default function ConfirmSMSModal(props: OwnProps): JSX.Element {
  const { onCancel, isOpen, handleSubmit, handleResend, error, isLoading } =
    props;
  const { getAppValues, setAppValues } = useAppValues();
  const { verificationCode, screenSize } = getAppValues();
  const isNotPhone =
    screenSize !== ScreenSizes.XS && screenSize !== ScreenSizes.SM;

  const handleVerificationCode = (event: ChangeEvent<HTMLInputElement>) => {
    setAppValues({ verificationCode: event.target.value });
  };

  return (
    <Modal onClose={onCancel} isOpen={isOpen}>
      <Flex
        justifyContent="center"
        alignItems="center"
        width="100%"
        flexDir="column"
        paddingY="50px"
        paddingX="64px"
      >
        <Heading
          textAlign="center"
          marginBottom="40px"
          type={HeadingType.HEADING_MED}
        >
          Confirm your identity
        </Heading>
        <Text textAlign="center" marginBottom="40px">
          Please enter the 6-digit confirmation code we sent to your phone.
        </Text>

        <Input
          marginBottom="16px"
          width={isNotPhone ? "100%" : "192px"}
          placeholder="Enter Code"
          onChange={handleVerificationCode}
          value={verificationCode || ""}
        />
        <Button
          width={isNotPhone ? "100%" : "110px"}
          textType={TextType.BUTTON}
          styleType={ButtonType.PRIMARY}
          onClick={handleSubmit}
          isLoading={isLoading}
          isDisabled={!verificationCode}
        >
          Confirm
        </Button>

        {Boolean(error) && (
          <Text marginY="16px" type={TextType.ERROR}>
            {error}
          </Text>
        )}

        <Flex
          marginLeft={isNotPhone ? "-16px" : "0px"}
          marginTop="16px"
          width="100%"
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Button
            styleType={ButtonType.TEXT_BUTTON_BLACK}
            textDecoration="underline"
            textTransform="none"
            onClick={handleResend}
          >
            Send the code again
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
}

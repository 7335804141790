import { getAuthClient } from "lib/http/api";
import { useCallback, useState, useEffect } from "react";
import {
  UpdateAccountParams,
  UpdateAccountRes,
  User,
} from "core/account/types";
import { encodeToCamelCase, encodeToSnakeCase } from "lib/http/formatForServer";
import { call } from "lib/http/call";
import { MethodType, ResponseStatus } from "lib/http/types";
import { ServiceEndpoints } from "lib/http/constants";

interface Options {
  userRefId?: string | null;
}
export function useCustomer(options: Options): {
  customer: User;
  usrError: string | null;
  usrIsLoading: boolean;
  patch: (
    refId: string,
    params: UpdateAccountParams
  ) => Promise<UpdateAccountRes>;
  isPatching: boolean;
} {
  const { userRefId } = options;
  const [isPatching, setIsPatching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [customer, setCustomer] = useState<any>({});
  const [error, setError] = useState<string | null>(null);

  const callGetUser = useCallback(async (userRefId: string) => {
    setIsLoading(true);
    const result = await call<any>({
      endpoint: `${ServiceEndpoints.AUTH}/v1/users/${userRefId}`,
      method: MethodType.GET,
    });
    if (result.status === ResponseStatus.SUCCESS) {
      setCustomer(result.body);
    } else {
      setError(result.error);
    }
    setIsLoading(false);
  }, []);

  const patch = useCallback(
    async (
      userRefId: string,
      params: UpdateAccountParams
    ): Promise<UpdateAccountRes> => {
      const body = encodeToSnakeCase(params);
      setIsPatching(true);
      return getAuthClient()
        .patch(`/v1/users/${userRefId}`, body)
        .then((res) => {
          if (res.status !== 200) {
            return {
              error: `${res.status}: ${res.statusText}`,
              success: false,
            };
          }

          const updated: User = res.data ? encodeToCamelCase(res.data) : {};
          setCustomer({
            ...customer,
            ...updated,
          });
          return {
            error: null,
            success: true,
          };
        })
        .catch((e: any) => {
          return {
            error: e,
            succes: false,
          };
        })
        .finally(() => setIsPatching(false));
    },
    [setIsPatching]
  );

  useEffect(() => {
    if (!userRefId) {
      setIsLoading(false);
      return;
    }
    callGetUser(userRefId);
  }, [userRefId]);

  return {
    customer: customer,
    isPatching,
    patch,
    usrError: error,
    usrIsLoading: isLoading,
  };
}

import { useState } from "react";
import LoginModal from "core/auth/LoginModal";
import { useLogin } from "core/auth/useLogin";
import ConfirmSMSModal from "./ConfirmSMSModal";
import { useAppValues } from "lib/context/AppContext";
import { setCookie } from "nookies";
import CreateAccountModal from "./CreateAccountModal";
import { ORIGIN } from "./types";
import { patchCustomer } from "./patchCustomer";
import * as gtag from "lib/analytics/gtag";
import { AnalyticsEvents } from "lib/analytics/constants";
import { track } from "lib/analytics/analytics";

interface OwnProps {
  isOpen: boolean;
  refreshAuthAndClose: (isFirstTimer: boolean) => void;
  onCancel: () => void;
}

enum LoginModalState {
  SIGN_IN = "SIGN_IN",
  CONFIRM_IDENTITY = "CONFIRM_IDENTITY",
  CREATE_ACCOUNT = "CREATE_ACCOUNT",
}

export default function LoginFlowModalWrapper(
  props: OwnProps
): JSX.Element | null {
  const { refreshAuthAndClose, onCancel, isOpen } = props;
  const [currentLoginStep, setLoginStep] = useState<LoginModalState>(
    LoginModalState.SIGN_IN
  );
  const [error, setError] = useState<string | null>(null);
  const { getAppValues, setAppValues } = useAppValues();
  const { phone, verificationCode, isSignup, email, firstName, lastName } =
    getAppValues();
  const { isRequestingCode, isLoggingIn, getCode, login } = useLogin();

  const handleCancel = () => {
    let trackName = "cancel_sign_in";
    let trackNameSegment = AnalyticsEvents.CANCEL_SIGN_IN;
    switch (currentLoginStep) {
      case LoginModalState.SIGN_IN:
      default:
        break;
      case LoginModalState.CREATE_ACCOUNT:
        trackName = "cancel_create_account";
        trackNameSegment = AnalyticsEvents.CANCEL_CREATE_ACCOUNT;
        break;
      case LoginModalState.CONFIRM_IDENTITY:
        trackName = "cancel_confirm_identity";
        trackNameSegment = AnalyticsEvents.CANCEL_CONFIRM_IDENTITY;
        break;
    }
    gtag.event({
      action: trackName,
      category: "engagement",
    });
    track(trackNameSegment);
    onCancel();
    setLoginStep(LoginModalState.SIGN_IN);
  };

  const handleGetVerificationCode = async (createAccountFlow?: boolean) => {
    if (!phone) {
      setError("Please enter your phone number");
      return;
    }
    gtag.event({
      action: "submit_phone_number_attempt",
      category: "engagement",
    });
    track(AnalyticsEvents.SUBMIT_PHONE_ATTEMPT);
    const queryParams = `+${phone}`;
    const success = await getCode(queryParams);
    if (!success) {
      setError("Error sending Code to that number.");
      gtag.event({
        action: "submit_phone_number_error",
        category: "engagement",
      });
      track(AnalyticsEvents.SUBMIT_PHONE_ERROR);
      return;
    }
    if (createAccountFlow) {
      setAppValues({ isSignup: true });
    } else {
      isSignup && setAppValues({ isSignup: false });
    }
    setLoginStep(LoginModalState.CONFIRM_IDENTITY);
  };

  const handleLogin = async () => {
    if (!verificationCode) {
      setError("Please enter your verification code.");
      return;
    }
    const loginRes = await login({
      origin: ORIGIN.WEB,
      phone: `+${phone}`,
      verificationCode: verificationCode,
    });
    if (!loginRes) {
      gtag.event({
        action: "submit_sms_error",
        category: "engagement",
      });
      track(AnalyticsEvents.SUBMIT_SMS_ERROR);
      setError("Error. Please check code and try again.");
      return;
    }
    gtag.event({
      action: loginRes.isFirstTimer ? "signup_success" : "login_success",
      category: "engagement",
    });
    const trackName = loginRes.isFirstTimer
      ? AnalyticsEvents.SIGNUP_SUCCESS
      : AnalyticsEvents.LOGIN_SUCCESS;
    track(trackName);

    if (isSignup) {
      const patchRes = await patchCustomer(loginRes.userRefId, {
        email: email ?? null,
        firstName: firstName ?? null,
        lastName: lastName ?? null,
      });
      if (!patchRes.success) {
        console.warn("patch info silent failure after signup");
      }
    }

    setAppValues({
      country: loginRes.country,
      countryAlpha2: loginRes.countryAlpha2,
      isSignup: false,
      userRefId: loginRes.userRefId,
    });
    setCookie(null, "userRefId", loginRes.userRefId, {});
    refreshAuthAndClose(loginRes.isFirstTimer);
  };

  const handleSwitchToCreate = () => {
    gtag.event({
      action: "switch_to_create_account_clicked",
      category: "engagement",
    });
    track(AnalyticsEvents.CLICKED_SWITCH_TO_CREATE);
    setLoginStep(LoginModalState.CREATE_ACCOUNT);
  };

  const handleSwitchToLogin = () => {
    gtag.event({
      action: "switch_to_login_clicked",
      category: "engagement",
    });
    track(AnalyticsEvents.CLICKED_SWITCH_TO_LOGIN);
    setLoginStep(LoginModalState.SIGN_IN);
  };

  if (!isOpen) {
    return null;
  }

  // Should be a switch probably

  if (currentLoginStep === LoginModalState.SIGN_IN) {
    return (
      <LoginModal
        isOpen={currentLoginStep === LoginModalState.SIGN_IN}
        onCancel={handleCancel}
        handleSubmit={handleGetVerificationCode}
        handleSwitchToCreate={handleSwitchToCreate}
        error={error}
        isLoading={isRequestingCode}
      />
    );
  }

  if (currentLoginStep === LoginModalState.CONFIRM_IDENTITY) {
    return (
      <ConfirmSMSModal
        isOpen={currentLoginStep === LoginModalState.CONFIRM_IDENTITY}
        onCancel={handleCancel}
        handleSubmit={handleLogin}
        handleResend={handleGetVerificationCode}
        error={error}
        isLoading={isLoggingIn}
      />
    );
  }

  if (currentLoginStep === LoginModalState.CREATE_ACCOUNT) {
    return (
      <CreateAccountModal
        isOpen={currentLoginStep === LoginModalState.CREATE_ACCOUNT}
        onCancel={handleCancel}
        handleSubmit={handleGetVerificationCode}
        handleSwitchToLogin={handleSwitchToLogin}
        error={error}
        isLoading={isLoggingIn}
      />
    );
  }

  return null;
}

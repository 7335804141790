import { Flex, useToast } from "@chakra-ui/react";
import Modal from "components/Modal";
import Button, { ButtonType } from "components/Button";
import Heading, { HeadingType } from "components/Heading";
import Text, { TextType } from "components/Text_DEPRECATED";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { handleSendAppToPhone } from "core/auth/service";
import * as gtag from "lib/analytics/gtag";
import {
  AnalyticsEvents,
  InternalAnalyticsEvents,
} from "lib/analytics/constants";
import { track } from "lib/analytics/analytics";
import React, { useState, ReactElement } from "react";
import { useTracking } from "core/tracking/useTracking";
import { ForceCreateModalType } from "lib/context/AppContext";
import { ResponseStatus } from "lib/http/types";

interface Props {
  header: string;
  path?: string;
  modalEnabled: ForceCreateModalType | null;
  currPhone: string | null;
  handleToggleModal: () => void;
  mobile?: boolean;
}

export default function SendAppToPhoneModal({
  header,
  mobile,
  modalEnabled,
  currPhone,
  path,
  handleToggleModal,
}: Props): ReactElement {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [phone, setPhone] = useState<string | null>(currPhone);
  const [error, setError] = useState<string | null>(null);
  const toast = useToast();
  const { internalTrackEvent } = useTracking();

  const handlePhone = (value: string) => {
    setPhone(value);
  };

  const handleSubmit = async () => {
    error && setError(null);
    if (!phone) {
      setError("Phone is required");
      return;
    }
    setIsLoading(true);
    const result = await handleSendAppToPhone(phone);
    if (result.type === ResponseStatus.ERROR) {
      setIsLoading(false);
      setError(result.error);
    } else {
      toast({
        description: "Successfully sent.",
        duration: 3000,
        isClosable: true,
        position: "top-right",
        status: "success",
        title: "Success",
      });
      gtag.event({
        action: "send_app_to_phone_success",
        category: "engagement",
      });

      internalTrackEvent(InternalAnalyticsEvents.SEND_APP_TO_PHONE_SUCCESS, {
        path: path,
        phone: phone,
      });
      track(AnalyticsEvents.SEND_APP_TO_PHONE_SUCCESS);
      handleToggleModal();
    }
  };

  const handleClose = () => {
    handleToggleModal();
  };

  return (
    <Modal isOpen={modalEnabled !== null} onClose={handleClose}>
      <Flex
        justifyContent="center"
        alignItems="center"
        flexDir="column"
        paddingY="50px"
        paddingX={mobile ? "8px" : "64px"}
      >
        <Heading
          textAlign="center"
          marginBottom="40px"
          type={HeadingType.HEADING_MED}
        >
          {header}
        </Heading>
        <Text textAlign="center" marginBottom="40px">
          {`Send the app to your phone to ${
            ForceCreateModalType.NEWS
              ? "view news, apply to jobs,"
              : "apply to jobs"
          } and gain an advantage over
          your competition by becoming a 10ure verified candidate.`}
        </Text>
        <PhoneInput
          country="us"
          placeholder="Phone number"
          value={phone}
          inputClass="phoneInput"
          buttonClass="phoneButton"
          onChange={handlePhone}
          inputStyle={{
            fontFamily: "SFProDisplay",
            fontSize: "16px",
            fontWeight: 500,
            letterSpacing: "0.75px",
            lineHeight: "28px",
            width: "100%",
          }}
          buttonStyle={{
            backgroundColor: "transparent",
          }}
        />
        <Button
          width="100%"
          marginTop="16px"
          textType={TextType.BUTTON}
          styleType={ButtonType.PRIMARY}
          onClick={handleSubmit}
          isLoading={isLoading}
          isDisabled={!phone}
        >
          Send Link
        </Button>

        {Boolean(error) && (
          <Text marginY="16px" type={TextType.ERROR}>
            {error}
          </Text>
        )}
      </Flex>
    </Modal>
  );
}

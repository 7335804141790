import { Flex } from "@chakra-ui/react";
import Button, { ButtonType } from "components/Button";
import Heading, { HeadingType } from "components/Heading";
import Text, { TextType } from "components/Text_DEPRECATED";
import { ChangeEvent, useState } from "react";

import Modal from "components/Modal";
import FormInput from "components/FormInput";
import { useAppValues } from "lib/context/AppContext";
import { validateEmail } from "lib/validateEmail";
import { formatEmail } from "lib/formatEmail";
import PhoneInput from "react-phone-input-2";
import { ScreenSizes } from "core/home/types";

interface OwnProps {
  isOpen: boolean;
  onCancel: () => void;
  handleSubmit: (isSignup?: boolean) => void;
  handleSwitchToLogin: () => void;
  error: string | null;
  isLoading: boolean;
}

export default function CreateAccountModal(props: OwnProps): JSX.Element {
  const { getAppValues, setAppValues } = useAppValues();
  const { phone, email, firstName, lastName, screenSize } = getAppValues();
  const { onCancel, isOpen, isLoading, handleSubmit, handleSwitchToLogin } =
    props;
  const [error, setError] = useState<string | null>(null);
  const isNotPhone =
    screenSize !== ScreenSizes.XS && screenSize !== ScreenSizes.SM;

  const reduceInputs = (event: ChangeEvent<HTMLInputElement>) => {
    error && setError(null);
    const key = event.target.id;
    const value = event.target.value;
    setAppValues({ [key]: value });
  };

  const handlePhone = (value: string) => {
    setAppValues({ phone: value });
  };

  const handleCreateAccount = () => {
    if (email) {
      const invalidEmail = validateEmail(email);
      if (invalidEmail) {
        setError(invalidEmail);
        return;
      }
      const formattedEmail = formatEmail(email);
      setAppValues({ email: formattedEmail });
    }
    handleSubmit(true);
  };

  return (
    <Modal onClose={onCancel} isOpen={isOpen}>
      <Flex
        justifyContent="center"
        alignItems="center"
        flexDir="column"
        paddingY="50px"
        paddingX="64px"
      >
        <Heading
          textAlign="center"
          marginBottom="40px"
          type={HeadingType.HEADING_MED}
        >
          Sign up
        </Heading>

        <Flex
          maxW="400px"
          w="400px"
          flexWrap="wrap"
          flexDir={isNotPhone ? "row" : "column"}
          marginTop="40px"
          justifyContent={isNotPhone ? "space-between" : undefined}
          alignItems={isNotPhone ? "auto" : "center"}
        >
          <FormInput
            id={"firstName"}
            defaultValue={firstName ?? ""}
            placeholder="First Name"
            w="192px"
            h="48px"
            key={"firstName"}
            onChange={reduceInputs}
          />

          <FormInput
            id={"lastName"}
            defaultValue={lastName || ""}
            placeholder="Last Name"
            w="192px"
            h="48px"
            marginTop={isNotPhone ? undefined : "16px"}
            key={"lastName"}
            onChange={reduceInputs}
            marginBottom="8px"
          />
        </Flex>
        <PhoneInput
          country="us"
          placeholder="Phone number"
          value={phone}
          inputClass="phoneInput"
          buttonClass="phoneButton"
          onChange={handlePhone}
          inputStyle={{
            fontFamily: "SFProDisplay",
            fontSize: "16px",
            fontWeight: 500,
            letterSpacing: "0.75px",
            lineHeight: "28px",
            width: "100%",
          }}
          buttonStyle={{
            backgroundColor: "transparent",
          }}
        />
        <FormInput
          marginTop="16px"
          id={"email"}
          defaultValue={email ?? ""}
          placeholder="Email"
          w={isNotPhone ? "400px" : "192px"}
          h="48px"
          key={"email"}
          onChange={reduceInputs}
        />

        <Button
          width={isNotPhone ? "100%" : "110px"}
          height="64px"
          marginTop="40px"
          textType={TextType.BUTTON}
          styleType={ButtonType.PRIMARY}
          onClick={handleCreateAccount}
          isLoading={isLoading}
        >
          Sign up
        </Button>

        {Boolean(error) && (
          <Text marginY="16px" type={TextType.ERROR}>
            {error}
          </Text>
        )}

        <Flex flexDirection="row" justifyContent="center" alignItems="center">
          <Text>Or</Text>
          <Button
            styleType={ButtonType.TEXT_BUTTON}
            onClick={handleSwitchToLogin}
          >
            Login
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
}

import { getAuthClient } from "lib/http/api";
import { useCallback, useState } from "react";
import { encodeToSnakeCase } from "lib/http/formatForServer";
import { AxiosResponse } from "axios";
import { setToken } from "lib/http/session";
import { LoginParams, LoginRawResponse, AuthResponse } from "core/auth/types";
import { decodeLogin } from "core/auth/payload";
import { destroyCookie } from "nookies";

export function useLogin(): {
  getCode: (params: string) => Promise<boolean>;
  isRequestingCode: boolean;
  login: (params: LoginParams) => Promise<AuthResponse | null>;
  isLoggingIn: boolean;
} {
  const [isRequestingCode, setRequestingCode] = useState(false);
  const [isLoggingIn, setLoggingIn] = useState(false);

  const getCode = useCallback(
    async (phone: string): Promise<boolean> => {
      setRequestingCode(true);
      return getAuthClient()
        .get(`v1/phone/verification/code?phone=${phone}&origin=web`)
        .then((res: AxiosResponse<boolean>) => {
          if (res.status == 409) {
            console.warn("409");
            return false;
          }
          return true;
        })
        .catch((e: any) => {
          console.warn("getCode - e: ", e);
          return false;
        })
        .finally(() => {
          setRequestingCode(false);
          return false;
        });
    },
    [setRequestingCode]
  );

  const login = useCallback(
    async (params: LoginParams): Promise<AuthResponse | null> => {
      setLoggingIn(true);
      const body = encodeToSnakeCase(params);
      return getAuthClient()
        .post("v1/phone/login", body)
        .then((res: AxiosResponse<LoginRawResponse>) => {
          if (res.status == 409) {
            console.warn("409");
            return null;
          }

          const resRaw = res.data;
          const formattedRes = decodeLogin(resRaw);
          if (!formattedRes) {
            console.warn("Error unpacking data");
            return null;
          }

          setToken(formattedRes.token);
          localStorage.setItem("userRefId", formattedRes.userRefId);
          localStorage.setItem(
            "isFirstTimer",
            `${formattedRes.isFirstTimer ?? false}`
          );
          destroyCookie(null, "xToken");
          analytics.identify(formattedRes.userRefId, {
            country: formattedRes.country,
            countryAlpha2: formattedRes.countryAlpha2,
            origin: params.origin,
            phone: params.phone,
          });
          return formattedRes;
        })
        .catch((e: any) => {
          console.warn("getCode : ", e);
          return null;
        })
        .finally(() => {
          setLoggingIn(false);
          return null;
        });
    },
    [setLoggingIn]
  );

  return {
    getCode,
    isLoggingIn,
    isRequestingCode,
    login,
  };
}

import React, { ReactElement } from "react";

export function VerifiedLogoLarge() {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="28"
        cy="28"
        r="27.25"
        fill="#3CACEB"
        stroke="#3CACEB"
        strokeWidth="1.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.8116 17.3888C44.0331 18.6102 44.0331 20.5907 42.8116 21.8122L25.2137 39.4103C24.623 40.001 23.8205 40.3309 22.9851 40.3264C22.1498 40.3219 21.3509 39.9834 20.7666 39.3864L11.7645 30.1882C10.5562 28.9537 10.5776 26.9733 11.8121 25.7651C13.0467 24.5568 15.027 24.5781 16.2353 25.8127L23.0259 32.7512L38.3882 17.3888C39.6097 16.1673 41.5901 16.1673 42.8116 17.3888Z"
        fill="white"
      />
    </svg>
  );
}

export function VerifiedLogoSmall(): ReactElement {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="16"
        cy="16"
        r="15.5714"
        fill="#3CACEB"
        stroke="#3CACEB"
        strokeWidth="0.857143"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.4638 9.93657C25.1618 10.6346 25.1618 11.7662 24.4638 12.4642L14.4079 22.5203C14.0703 22.8579 13.6118 23.0464 13.1344 23.0438C12.657 23.0412 12.2005 22.8478 11.8667 22.5066L6.7226 17.2506C6.03217 16.5451 6.04436 15.4135 6.74983 14.723C7.4553 14.0326 8.58691 14.0448 9.27735 14.7503L13.1577 18.7151L21.9361 9.93659C22.6341 9.23859 23.7658 9.23858 24.4638 9.93657Z"
        fill="white"
      />
    </svg>
  );
}

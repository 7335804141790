import { MutableRefObject, useEffect, useRef } from "react";

/**
 * Hook to verify whether your component is still mounted or not
 */
export function useIsMounted(): MutableRefObject<boolean> {
  const isMounted = useRef<boolean>(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  });

  return isMounted;
}

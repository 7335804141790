// eslint-disable-next-line
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function validateEmail(email?: string | null): string | null {
  if (!email || email.length === 0) {
    return "Please enter an email.";
  }

  // check email against known bad endings, exist early if typo
  const knownBadEmailEndings = [".con", ".col", ".cok"];
  for (let i = 0; i < knownBadEmailEndings.length; i++) {
    if (email.endsWith(knownBadEmailEndings[i])) {
      return `That email ending looks like a typo:  ${knownBadEmailEndings[i]}`;
    }
  }

  if (EMAIL_REGEX.test(email)) {
    return null;
  }

  return "That email is not valid.";
}

export function isEmailValid(email?: string | null): boolean {
  return Boolean(validateEmail(email));
}

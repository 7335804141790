import { AuthResponse, LoginRawResponse } from "core/auth/types";

export function decodeLogin(data: LoginRawResponse): AuthResponse | null {
  try {
    return {
      country: data.country,
      countryAlpha2: data.country_alpha_2,
      dashboardUsrRefId: data.dashboard_usr_ref_id ?? null,
      isFirstTimer: data.is_first_timer ?? false,
      isImpersonating: false,
      registrationComplete: data.registration_complete ?? false,
      token: data.token,
      userRefId: data.user_ref_id,
    };
  } catch {
    return null;
  }
}

export enum ORIGIN {
  WEB = "web",
}

export interface LoginParams {
  phone: string;
  verificationCode: string;
  origin: ORIGIN;
}

export interface AuthResponse {
  token: string;
  userRefId: string;
  country: string;
  countryAlpha2: string;
  isFirstTimer: boolean;
  isImpersonating: boolean;
  registrationComplete: boolean;
  dashboardUsrRefId: string | null;
  appContext?: any;
}

export interface LoginRawResponse {
  app_context: any;
  country: string;
  country_alpha_2: string;
  token: string;
  user_ref_id: string;
  is_first_timer: boolean;
  registration_complete?: boolean;
  dashboard_usr_ref_id?: string;
}

export interface WebContext {
  showSendAppToPhoneButton: boolean;
}

export interface JobApplyBaseParams {
  firstName: string;
  lastName: string;
  email?: string;
  phoneNumber: string;
  phoneVerificationCode: string;
}

/**
 * Old flow
 */
export interface QuickAuthBaseParams {
  firstName?: string;
  referenceNumber?: string; // MMC number
  lastName?: string;
  phoneNumber: string;
  email?: string;
  city?: string;
  phoneVerificationCode: string;
}

export interface QuickAuthParams extends QuickAuthBaseParams {
  resume?: File;
  fileName?: string;
}

export interface Resume {
  createdAt: string;
  fileName: string;
  refId: string;
  updatedAt: string;
  url: string;
}

export interface UploadResumeParams {
  fileName: string;
  resume: File;
}

import { OrganizationCredentialShare } from "core/all-credentials/types";
import { SeaTime } from "core/seatime/types";
import { ServiceEndpoints } from "lib/http/constants";
import { ByStringId } from "lib/types/common";

export enum CredentialSectionHeaders {
  LICENSES = "Licenses",
  CERTIFICATIONS = "Certifications",
  IDENTIFICATIONS = "Identifications",
  COVID = "Vaccination",
}

/**
 * Possibly deprecated
 */
export enum BackendTypeEnum {
  MMC = "MMC",
  MEDICAL_CERT = "Medical Certificate",
  ECDIS = "ECDIS",
  GMDSS = "GMDSS",
  ARPA = "RADAR/ARPA",
  CPR = "CPR",
  TWIC = "TWIC",
  PASSPORT = "Passport",
  DYNAMIC_POSITIONING = "Dynamic Positioning",
  OTHER = "Other",
  COVID = "Covid",

  // PH
  COC_COP = "PH_MMC",
  SID = "SID",
  SRB = "SRB",
}

export enum CaptureLayout {
  PORTRAIT = "portrait",
  LANDSCAPE = "landscape",
}

export enum BodyDescription {
  MMC = "Focus on the bottom half of your MMC Document as shown below",
  ENTIRE_DOCUMENT = "Focus on the entire document as shown below",
  PASSPORT = "Focus on the bottom half of passport as shown below",
}

export enum UploadStatus {
  UNKNOWN = "unknown",
  SKIPPED = "skipped",
  SUCCESS = "success",
}

export enum InputNumber {
  ONE = "ONE",
  TWO = "TWO",
}

export enum AddCredentialFlow {
  REGULAR = "REGULAR",
  NETWORK = "NETWORK",
}

export enum OneOffCredTypes {
  COVID = "Covid-19 Vaccination Record",
  OTHER = "Other",
}

export interface DocumentPreview {
  title: string;
  expirationDate?: string | null;
  issuedDate?: string | null;
  type: CredentialFamily | OneOffCredTypes;
  refId: string;
  number?: string; // ex: mmc reference number
  isValidated: boolean | null;
  validatedBy: string | null;
  organizationShares: OrganizationCredentialShare[] | null;
  imageUrl?: string;
  // sharedWithOrganizations?: Organization[] | null;
}

export interface Credential {
  issuedDate?: string | null;
  expirationDate?: string | null;
  lastName?: string | null;
  number?: string | null;
  id?: string | null;
  refId: string;
  title: string;
  imageUrl?: string;
  externalDetailsUrl?: string;
  type: string;
  internationalRegulationsSection?: InternationalRegulationsSection;
  internationalCapacityAndLimitationsSection?: InternationalCapacityAndLimitationsSection;
  domesticCapacityAndLimitationsSection?: DomesticCapacityAndLimitationsSection;
}

export interface AdditionalDocumentsSection {
  isOpen: boolean;
}
export interface InternationalRegulationsSection {
  isOpen: boolean;
  regulations: InternationalRegulation[];
}

export interface InternationalRegulation {
  refId: string;
  regulation: string;
}

export interface InternationalCapacityAndLimitationsSection {
  isOpen: boolean;
  capacitiesAndRegulations: CapacityAndLimitation[];
}

export interface CapacityAndLimitation {
  refId: string;
  type: string;
  capacity: string;
  limitation?: string | null;
}

export interface DomesticCapacityAndLimitationsSection {
  isOpen: boolean;
  capacitiesAndRegulations: CapacityAndLimitation[];
}

export interface SeaTimeSection {
  sea_time: SeaTime[] | null;
  is_open?: boolean;
}

export interface AdditionalDocument {
  created_at: string;
  dashboard_usr_first_name?: string | null;
  dashboard_usr_last_name?: string | null;
  name: string;
  ref_id: string;
  url: string;
}

/**
 * title: Credential Search Section Header
 * data: Credential List
 */
export interface CredentialListWithHeaders {
  title: CredentialSectionHeaders;
  data: DocumentPreview[];
}

export interface RawCredentials {
  licenses: ByStringId<DocumentPreview>;
  certifications: ByStringId<DocumentPreview>;
  identifications: ByStringId<DocumentPreview>;
}

export enum BackendPayloadKey {
  REFERENCE_NUMBER = "referenceNumber",
  DOCUMENT_NUMBER = "documentNumber",
  REFERENCE_NUMBER_V2 = "reference_number_v_2",
  ISSUED_DATE = "issuedDate",
  EXPIRATION_DATE = "expirationDate",
  LAST_NAME = "lastName",
  FIRST_NAME = "firstName",
  BIRTH_DATE = "birthDate",
}

export enum CredentialFamily {
  DOCUMENT = "DOCUMENT",
  CERTIFICATE = "CERTIFICATE",
  IDENTIFICATION = "IDENTIFICATION",
}

// not needed
export type KeyboardType =
  | "default"
  | "email-address"
  | "numeric"
  | "phone-pad"
  | "number-pad"
  | "decimal-pad";

// NOTE - can condense inputLabel and backendPayloadKey to be same
export interface CredentialFormInputParams {
  inputValue: string | undefined;
  inputTempValue: string | undefined; // holder while editing
  inputDisplayText: string;
  inputPlaceholder: string;
  inputKeyboardType: KeyboardType;
  inputValueType: InputValueType;
  backendPayloadKey: BackendPayloadKey | string;
  disabled?: boolean;
  allCaps: boolean;
}

export interface CredentialFormInputParamsRaw {
  value: string | undefined;
  display_text: string;
  placeholder: string;
  keyboard_type: string;
  value_type: string;
  payload_key: string;
  all_caps: boolean | undefined;
}

export interface EndpointInformation {
  uri: string;
  service: ServiceEndpoints;
}
export interface EndpointInformationRaw {
  uri: string;
  service: string;
}

// Used to aide in the formatiting of 'Other'
export type PartialCredInfo = Pick<
CredentialInformation,
"modifyEndpoint" | "title" | "credentialType" | "credentialFamily"
>;

export interface CredentialInformation {
  allowPhoto: boolean;
  onboardPromptText: string | null;
  onboardHeaderText: string | null;
  additionalInfoText: string | null;
  cameraPromptHeaderOnboard: string | null;
  cameraPromptHeaderDefault: string;
  cameraPromptHeaderDescription: string;
  cameraPromptBodyDescription: BodyDescription;
  imageSource: string;
  layout: CaptureLayout;
  cameraHeader: string;
  credentialType: BackendTypeEnum;
  credentialFamily: CredentialFamily;
  // For GET request - the credential is in the zero spot
  endpoints: EndpointInformation[];
  modifyEndpoint: EndpointInformation;
  showVerified: boolean;
  successScreen: SuccessScreen | null;
  dropdownName: string;
  screenTitle: string;
  title: string;
  inputs: CredentialFormInputParams[];
  errors: ByStringId<ErrorOption> | null;
  // To help aid in onboarding
  uploadStatus: UploadStatus;
  // To keep track of ref Id to pass to camera screen
  credentialRefId: string | null;
}

export interface ErrorButtonRaw {
  title: string;
  type: ErrorButtonTypes;
}

export interface ErrorButton {
  title: string;
  type: ErrorButtonTypes;
}

export enum ErrorButtonTypes {
  CONTINUE = "continue",
  SKIP = "skip",
  RETRY = "retry",
  GO_BACK = "go_back",
}

export interface ErrorOption {
  title: string;
  description: string;
  descriptionLink: string | null;
  imageUrl: string | null;
  buttons: ErrorButton[];
}

export interface ErrorOptionRaw {
  title: string;
  description: string;
  description_link?: string | null;
  status_code: number;
  image_url?: string | null;
  buttons: ErrorButton[];
}

export interface CurrentCredential {
  refId: string;
  imageUrl?: string | null;
  inputs: CredentialFormInputParams[];
  errors: ByStringId<ErrorOption>;
  endpoints: EndpointInformation[];
  modify_endpoint: EndpointInformation;
  credentialFamily: CredentialFamily;
  expirationDate: string | null;
  screenTitle: string;
  isValidated: boolean;
  organizationShares: OrganizationCredentialShare[];
}

export interface CredentialInformationRaw {
  onboard_prompt_text: string | null;
  onboard_header_text: string | null;
  description_text: string | null;
  additional_info_text: string | null;
  // Key to say whether to go to photo step or not
  capture_credential_image: boolean | null;
  camera_prompt_header_onboard: string | null;
  camera_prompt_header_default: string;
  camera_prompt_header_description: string;
  camera_prompt_body_description: string;
  image_source: string;
  layout: string;
  camera_header: string;
  credential_type: string;
  credential_family: string;
  endpoints: EndpointInformationRaw[];
  modify_endpoint: EndpointInformationRaw;
  dropdown_name: string;
  show_10ure_verified?: boolean | null;
  screen_title: string;
  title: string;
  allow_photo?: boolean;
  // Key for onboarding to say whether or not to go to the "Found these creds" screen
  success_screen: SuccessScreenRaw | null;
  inputs: CredentialFormInputParamsRaw[];
  errors?: ErrorOptionRaw[] | null;
}

export interface FormattedCredInfo {
  onboardingCredentials: string[];
  supportedCredentialTitles: string[];
  // above but formatted
  formattedTitles: string[];
  credentials: ByStringId<CredentialInformation>;
}

export interface SuccessScreen {
  additionalText?: string;
  buttonText?: string;
  text?: string;
  title?: string;
}

export interface SuccessScreenRaw {
  additional_text?: string;
  button_text?: string;
  text?: string;
  title?: string;
}

export enum InputValueType {
  STRING = "string",
  PH_STRING = "phString", // XX-XXXXX
  DATE = "date",
  NUMBER = "number",
}

/**
 * FORM TYPES
 */
// Note: All params are optional, depends on cert
export interface CertificateDetails {
  certificateType?: BackendTypeEnum;
  referenceNumber?: string;
  referenceId?: string;
  certificate?: string;
  lastName?: string;
  issuedDate?: string;
  expirationDate?: string;
  birthDate?: string;
  title: string;
  isValidated?: boolean | null;
  validatedBy?: string | null;
  organizationShares?: OrganizationCredentialShare[] | null;
  // note - OBE - was for "syncing demo"
  // sharedWithOrganizations?: Organization[] | null;
}

// Note: All params are optional, depends on ID
export interface IdentificationDetails {
  identificationType?: BackendTypeEnum;
  referenceNumber?: string;
  referenceId?: string;
  citizenship?: string;
  lastName?: string;
  issuedDate?: string;
  expirationDate?: string;
  birthDate?: string;
  expDate?: string; // For EDIT field
  title: string;
  isValidated?: boolean | null;
  validatedBy?: string | null;
  organizationShares?: OrganizationCredentialShare[] | null;
  // note - OBE - was for "syncing demo"
  // sharedWithOrganizations?: Organization[] | null;
}

// Note: All params are optional, depends on doc
export interface DocumentDetails {
  documentType?: BackendTypeEnum;
  // NOTE - BE requires document_number for now
  // will update to remove on refactor
  // for now just sending both since referenceNumber ignored
  documentNumber?: string;
  referenceNumber?: string;
  referenceId?: string;
  certificate?: string;
  lastName?: string;
  issuedDate?: string;
  expirationDate?: string;
  birthDate?: string;
  title: string;
  isValidated?: boolean | null;
  validatedBy?: string | null;
  organizationShares?: OrganizationCredentialShare[] | null;
  // note - OBE - was for "syncing demo"
  // sharedWithOrganizations?: Organization[] | null;
}

import { Flex } from "@chakra-ui/react";
import Button, { ButtonType } from "components/Button";
import Heading, { HeadingType } from "components/Heading";
import Text, { TextType } from "components/Text_DEPRECATED";
import { useAppValues } from "lib/context/AppContext";
import Modal from "components/Modal";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useRouter } from "next/router";
import { AppRoutes, ScreenSizes } from "core/home/types";
import { useEffect, useState } from "react";
import * as gtag from "lib/analytics/gtag";
import { AnalyticsEvents } from "lib/analytics/constants";
import { track } from "lib/analytics/analytics";

interface OwnProps {
  isOpen: boolean;
  onCancel: () => void;
  handleSubmit: () => void;
  handleSwitchToCreate: () => void;
  error: string | null;
  isLoading: boolean;
}

export default function LoginModal(props: OwnProps): JSX.Element {
  const {
    onCancel,
    isOpen,
    handleSubmit,
    handleSwitchToCreate,
    error,
    isLoading,
  } = props;
  const router = useRouter();
  const [isNews, setIsNews] = useState<boolean>(false);
  const { getAppValues, setAppValues } = useAppValues();
  const { phone, screenSize } = getAppValues();
  const isSmall =
    screenSize === ScreenSizes.XS || screenSize === ScreenSizes.SM;

  useEffect(() => {
    if (!router.isReady) return;
    const shouldSetIsNews = router.pathname === AppRoutes.NEWS_HOME;
    if (shouldSetIsNews) {
      setIsNews(true);
    }
  }, [router.isReady]);

  useEffect(() => {
    gtag.event({
      action: "sign_up_prompted",
      category: "engagement",
    });
    track(AnalyticsEvents.SIGNUP_PROMPTED);
  }, []);

  const handlePhone = (value: string) => {
    setAppValues({ phone: value });
  };

  return (
    <Modal onClose={onCancel} isOpen={isOpen}>
      <Flex
        justifyContent="center"
        alignItems="center"
        flexDir="column"
        paddingY="50px"
        paddingX="64px"
        minWidth={isSmall ? "300px" : "550px"}
      >
        <Heading
          textAlign="center"
          marginBottom="40px"
          type={HeadingType.HEADING_MED}
        >
          Sign in
        </Heading>
        <Text textAlign="center" marginBottom="40px">
          {isNews
            ? `Sign in to react to news`
            : "Sign in to view and apply to jobs"}
        </Text>
        <PhoneInput
          country="us"
          placeholder="Phone number"
          value={phone}
          inputClass="phoneInput"
          buttonClass="phoneButton"
          onChange={handlePhone}
          inputStyle={{
            fontFamily: "SFProDisplay",
            fontSize: "16px",
            fontWeight: 500,
            letterSpacing: "0.75px",
            lineHeight: "28px",
            width: "100%",
          }}
          buttonStyle={{
            backgroundColor: "transparent",
          }}
        />
        <Button
          width="100%"
          marginTop="16px"
          textType={TextType.BUTTON}
          styleType={ButtonType.PRIMARY}
          onClick={handleSubmit}
          isLoading={isLoading}
          isDisabled={!phone}
        >
          Sign In
        </Button>

        {Boolean(error) && (
          <Text marginY="16px" type={TextType.ERROR}>
            {error}
          </Text>
        )}

        <Flex flexDirection="row" justifyContent="center" alignItems="center">
          <Text>Or</Text>
          <Button
            styleType={ButtonType.TEXT_BUTTON}
            onClick={handleSwitchToCreate}
          >
            Create an account
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
}

import {
  Flex,
  FormControl,
  FormControlProps,
  InputProps,
} from "@chakra-ui/react";

import Input from "components/Input";
import ErrorIcon from "lib/svgs/Error";
import Text, { TextType } from "components/Text_DEPRECATED";

interface OwnProps {
  type?: string;
  placeholder: string;
  value?: string;
  defaultValue?: string;
  isinvalidHelperText?: string;
  inputProps?: Partial<InputProps>;
  disabledColor?: string;
}
type Props = OwnProps & FormControlProps;

/**
 * @deprecated - use FormInput from beta instead
 * @param props
 * @returns
 */
export default function FormInput(props: Props): JSX.Element {
  const { type, placeholder, value, defaultValue, inputProps, ...otherProps } =
    props;
  return (
    // @ts-ignore
    <FormControl {...otherProps}>
      <Flex
        marginBottom="16px"
        flexDir="column"
        justifyContent="flex-start"
        backgroundColor={props.disabledColor ?? undefined}
      >
        <Input
          {...inputProps}
          type={type}
          placeholder={placeholder}
          value={value}
          defaultValue={defaultValue}
        />
        {props.isInvalid && (
          <Flex flexDirection="row" alignItems="center">
            <ErrorIcon />
            <Text marginTop="2px" type={TextType.ERROR} marginLeft="4px">
              {props.isinvalidHelperText || `${placeholder} is required.`}
            </Text>
          </Flex>
        )}
      </Flex>
    </FormControl>
  );
}
